body {
  font-family: 'Baloo 2' !important;
  background-color: #A5F784 !important;
}

.stop-scrolling {
  overflow: hidden;
}

a:hover 
{
   text-decoration:none !important; 
}

h3 {
  font-size: 2em !important;
  font-weight: 600 !important;
}

@media only screen and (max-height: 600px) {
  h2 {
    font-weight: 400 !important;
    font-size: 1.25em !important;
  }
  h6 {
    font-size: 0.8em !important;
  }
  h3 {
    font-size: 2em !important;
  }
  h4 {
    font-size: 1em !important;
  }

  table {
    font-size: 0.75em !important;
  }
  
}

@media only screen and (min-height: 601px) {
  h2 {
    font-weight: 400 !important;
    font-size: 2em !important;
  }
}

@font-face {
  font-family: 'B4C';
  src: url('./Assets/font/B4C.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}