.scrollableContainer {
    display: flex;
    justify-content: center;
}

.Accordion {
    background-color: #F7976A !important;
    border: none !important;
    border-radius: none !important;
    text-decoration: none !important;
    color: black;
}

.AccordionHeader {
  background-color: none !important;
  border: none !important;
  border-radius: none !important;
  text-decoration: none !important;
  color: black;
}

.scrollables {
    font-family: 'B4C';
    font-size: 2.5em;
  }

.contactButton {
  padding: 1.25em;
  background-color: #5BAB75;
}

.simpleButton {
  padding: 1em;
  background-color: #F7976A;
}

.icons {
  display: flex;
  align-items: center;
}

.contactButton:hover {
    color: #387AAB;
    cursor: pointer;
}

.clickableTextArea {
    background-color: #F7976A;
    cursor: pointer;
}

.expandread {
    color: #A5F784;
  }

.expandread:hover {
    text-decoration: underline;
  }