
@media only screen and (max-width: 600px) {
  .headerTitle {
    font-size: 1.2em;
    font-weight: 600;
  }

  h2 {
    font-weight: 400 !important;
    font-size: 1em;
  }

  .header {
    padding: 1em;
  }
}

@media only screen and (min-width: 601px) {
  .headerTitle {
    font-size: 2.85em;
    font-weight: 600;
  }  

  .header {
    padding: 4em;
  }
}

.selectedLink {
    color: #387AAB !important;
    font-weight: 600;
  }

.fontgreen {
  color: #3A9C63;
}